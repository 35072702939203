<template>
  <v-col
    cols="12"
    v-bind="grid"
    class="mb-4"
  >
    <component
      @triggerFilter="$emit('triggerFilter')"
      :name="name"
      :config="config"
      :listKey="listKey"
      :is="component"
      :setupItemsCallback="setupItemsCallback"
    >
      <template v-slot:enabled="$props">
        <div class="d-flex mb-1">
          <v-checkbox
            @change="$props.onEnabledChange"
            dense
            hide-details
            v-model="$props.value"
          ></v-checkbox>

          <div class="pt-2 font-weight-bold subtitle-1">{{label}}</div>
        </div>
      </template>
    </component>
  </v-col>
</template>

<script>
export default {
  props: {
    listKey: String,
    name: String,
    config: Object,
    setupItemsCallback: Function,
  },
  computed: {
    label() {
      return this.$t(this.config.label)
    },
    grid() {
      let grid = { xl: 3, lg: 4, md: 4, sm: 6, xs: 12 }
      if(typeof this.config.grid != 'object') {
        return grid
      }

      if(this.config.grid.xl) grid.xl = this.config.grid.xl
      if(this.config.lg) grid.lg = this.config.lg
      if(this.config.md) grid.md = this.config.md
      if(this.config.sm) grid.sm = this.config.sm
      if(this.config.xs) grid.xs = this.config.xs
      return grid
    },
    component() {
      if(this.config.component) return this.config.component
      const component = this.$options.components[`filter-${this.config.type}`]
      if(!component) return 'filter-text'
      return component
    },
  },
  components: {
    'filter-text': () => import('@/components/list/listSearchAndFilter/filterText.vue'),
    'filter-date': () => import('@/components/list/listSearchAndFilter/filterDate.vue'),
    'filter-selection': () => import('@/components/list/listSearchAndFilter/filterSelection.vue'),
    'filter-selector': () => import('@/components/list/listSearchAndFilter/filterSelector.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>